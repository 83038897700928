import { Button } from '@boxine/tonies-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from '../ModalFooter/ModalFooter'
import { Text } from '../Typography'

export interface ChangeAccessModalProps {
  onClose: () => void
  type: 'full' | 'limited'
  onSubmit: () => void
}

export const ChangeAccessModalContent = ({
  onClose,
  type,
  onSubmit,
}: ChangeAccessModalProps) => {
  const { t } = useTranslation('default')

  return (
    <>
      {type === 'full' ? (
        <>
          <Text>{t('default:ChangeAccessModalBodyLimitedFirst')}</Text>
          <Text>{t('default:ChangeAccessModalBodyLimitedSecond')}</Text>
        </>
      ) : (
        <>
          <Text>{t('default:ChangeAccessModalBodyFullFirst')}</Text>
          <Text>{t('default:ChangeAccessModalBodyFullSecond')}</Text>
        </>
      )}
      <Text>{t('default:ChangeAccessModalBodyThird')}</Text>

      <ModalFooter>
        <Button variant="secondary" onClick={onClose}>
          {t('default:Cancel')}
        </Button>
        <Button onClick={onSubmit}>{t('default:Confirm')}</Button>
      </ModalFooter>
    </>
  )
}

ChangeAccessModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['full', 'limited']).isRequired,
}

export default ChangeAccessModalContent
