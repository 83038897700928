import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface SkeletonProps {
  width?: number | string
  height?: number | string
  circle?: boolean
  baseColor?: string
  hightlightColor?: string
}

export const skeletonAnim = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

const toCssLength = (input: string | number) => {
  return typeof input === 'number' ? input + 'px' : input
}

export const SkeletonInner = styled.span<SkeletonProps>`
  border-radius: ${props => (props.circle ? '50%' : '4px')};
  width: ${props => (props.width ? toCssLength(props.width) : '100%')};
  height: ${props => (props.height ? toCssLength(props.height) : '100%')};
  animation: ${skeletonAnim} 1.2s ease-in-out infinite;
  background-color: ${props => props.baseColor || '#eee'};
  background-image: linear-gradient(
    90deg,
    ${props => props.baseColor || '#eee'},
    ${props => props.hightlightColor || '#f5f5f5'},
    ${props => props.baseColor || '#eee'}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
`

export function Skeleton(props: SkeletonProps) {
  return (
    <SkeletonInner {...props} className="react-loading-skeleton">
      &nbsp;
    </SkeletonInner>
  )
}
