/* eslint-disable react/display-name */
import React, { useContext } from 'react'
import * as v from '@badrap/valita'
import {
  Stage,
  StageContent,
  theme,
  OmniButton,
  Headline2,
  Headline1,
  Paragraph,
  Spacing,
  Accent,
  Curve,
  media,
  UniqueSellingPoints,
  ImageV2Ctx,
  ImageV2,
  useMedia,
  UniqueSellingPointsItem,
} from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import styled, { ThemeContext, css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { MARKS, BLOCKS, Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useAuth } from '../../providers/auth'
import { InnerContainer, LayoutV2 } from '../../layouts/LayoutV2'
import { ContentfulHeadline } from '../../contentful/ContentfulRichText'
import { useFlag } from '../../providers/feature-flags'
import { useContentful } from '../../contentful'
import {
  contentfulDocument,
  contentfulFile,
} from '../../contentful/checkContentfulData'
import { Head } from '../../components/head'
// FIXME load as file; same file is also in src/assets !?!
import { ReactComponent as teaserBackground } from './images/teaser-background.svg'
// FIXME load as files!
import { ReactComponent as doodleBox } from './images/doodle-box.svg'
import { ReactComponent as doodleWlan } from './images/doodle-wlan.svg'

const StyledLayoutV2 = styled(LayoutV2)<{ backgroundColor: string }>`
  background-color: ${props => props.theme.White};

  ::before {
    background-color: ${props => props.backgroundColor};
  }
`

const StyledStage = styled(Stage)`
  margin-top: -1rem;
`

const StageButton = styled(OmniButton)`
  font-size: 0.75rem;
  ${media.mobileSM`
    font-size: 0.875rem;
  `}
`

const TwoTeaserSection = styled.section`
  display: grid;
  grid-gap: 2.5rem;
  grid-template-areas: 'Toniebox' 'Wifi';

  ${media.tabletL`
    grid-gap: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: 'Wifi Toniebox';
    `}
`

const TeaserWrapper = styled.article<{ gridArea: string }>`
  display: grid;
  grid-area: ${props => props.gridArea};
  grid-template-areas: 'headline' 'image' 'text' 'button';
  flex-direction: column;
  align-items: center;

  ${media.laptop`
    grid-template-rows: auto max-content auto;
    grid-template-areas:
      "image headline"
      "image text"
      "image button";
  `}
`

const TeaserImageWrapper = styled.div`
  grid-area: image;
  display: flex;
  justify-content: center;
`

const TeaserHeadline = styled.div`
  grid-area: headline;
  text-align: center;

  ${media.laptop`
    align-self: end;
    text-align: left;
  `}
`

const TeaserTextWrapper = styled.div`
  grid-area: text;
  text-align: center;

  ${media.laptop`
    text-align: left;
  `}
`

const TeaserButtonWrapper = styled.div`
  grid-area: button;
  text-align: center;

  ${media.laptop`
    align-self: start;
    text-align: left;
  `}
`

const TeaserParagraph = styled(Paragraph)`
  ${media.laptop`
    min-height: 2.5rem;
  `}
`

const TeaserBackground = styled(teaserBackground)`
  width: 100%;
  height: auto;

  path {
    fill: ${props => props.fill};
  }
`

const TeaserImageInner = styled.div`
  position: relative;
  max-width: 8.375rem;

  ${media.tabletL`
  max-width: none;
  margin-bottom: 1rem;
  `}
`

const teaserImageStyles = css`
  position: absolute;
  transform: translate(-50%, -50%);
  height: 5.125rem;
  top: 50%;
  left: 50%;
  z-index: 5;

  ${media.tabletL`
    height: 8.125rem;
    top: 68%;
  `}
`

const TeaserImageBox = styled(doodleBox)`
  ${teaserImageStyles}
`

const TeaserImageWlan = styled(doodleWlan)`
  ${teaserImageStyles}
`

const SectionInner = styled(InnerContainer)`
  width: 100%;
  padding: 0;
  margin-bottom: 3rem;

  ${media.tabletL`
    /* Match InnerContainer padding */
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`

const SectionHeadlineWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 2rem;
  line-height: 1.3;
  margin-bottom: 1rem;

  ${media.tabletL`
    padding: 0 0 2rem;
  `};
`
const TonieWorldSection = styled.section`
  position: relative;
  width: 100%;
  height: 45rem;
  margin-top: 8.75rem;
  background-color: ${props => props.theme.BrandPrimary};
  @media (min-width: 23.5rem) {
    margin-top: 11.5rem;
  }
  ${media.tabletL`
    height: 34rem;
  `}
  ${media.laptop`
    margin-top: 9rem;
  `}
`

const TonieWorldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'image'
    'headline'
    'paragraph'
    'links';
  grid-template-columns: 1fr;
  grid-template-rows: 40% 15% 20% 20%;
  align-items: center;
  justify-items: center;
  padding: 0 0.5rem;
  @media (min-width: 23.5rem) {
    grid-template-rows: 40% 15% 25% 20%;
  }
  ${media.tabletL`
    grid-template-areas:
    'headline  image image'
    'paragraph image image'
    'links image image';
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    justify-items: flex-start;
    padding-left: 2rem;
    padding-right: 0;
  `};
  ${media.laptop`
    grid-template-columns: 1.25fr 0.75fr 1fr;
    left: 50%;
    transform: translateX(-50%);
    max-width: 76rem;
  `};
  ${media.laptopL`
    grid-template-columns: 1.25fr 0.75fr 1fr;
  `};
`

const TonieWorldCurve = styled(Curve)`
  position: absolute;
  width: 100%;
  /* Prevent a white line between section and curve */
  top: calc(-1.875rem + 1px);
  z-index: 5;

  ${media.tablet`
    /* Prevent a white line between section and curve */
    top: calc(-3.375rem + 1px);
  `};
`

const TonieWorldHeadline = styled(Headline1)`
  max-width: 24ch;
  grid-area: headline;
  padding-bottom: 0 0.5rem 0.5rem;
  align-self: flex-end;
  text-align: center;
  line-height: 1.5;

  ${media.tabletL`
    text-align: left;
  `}
  @media (min-width: 100rem) {
    max-width: max-content;
    padding: 0 0 2rem;
  }
`

const TonieWorldParagraph = styled(Paragraph)`
  align-self: flex-start;
  grid-area: paragraph;
  max-width: 45ch;
  text-align: center;
  line-height: 1.3;

  ${media.tabletL`
    max-width: 60ch;
    text-align: left;
    margin-bottom: 1.5rem;
  `}

  ${media.laptop`
    margin-bottom: 0;
  `}
`

const TonieWorldImageWrapper = styled.div`
  grid-area: image;
  align-self: flex-start;
  justify-self: center;
  width: 19.25rem;

  ${media.tabletL`
    align-self: center;
    width: 80%;
    height: auto;
  `}
  ${media.laptop`
    width: 27.5rem;
  `};
`

const TonieWorldLinksWrapper = styled.div`
  flex-direction: column;
  height: 60%;
  grid-area: links;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  margin-top: 1rem;
  @media (min-width: 23.5rem) {
    align-self: center;
    margin-top: 0;
  }
  @media (min-width: 29rem) {
    align-self: flex-start;
  }
  ${media.tabletL`
    align-self: flex-start;
    flex-direction: row;
    height: auto;
  `}
`

const TonieWorldLogin = styled(OmniButton)`
  ${media.tabletL`
    margin-left: 2rem;
  `}
`

// TODO replace with proper loading state
// for now this at least avoids the footer flashing into view
const Placeholder = styled.div`
  height: 100vh;
`

const TwoTeaserSectionContainer = styled(InnerContainer)`
  height: auto;
  overflow: hidden;
`

export interface SetupTeaserProps {
  headline: React.ReactNode
  doodleImg: React.ReactNode
  text: React.ReactNode
  button: React.ReactNode
  backgroundFill: string
  gridArea: string
}

export function SetupTeaser({
  headline,
  text,
  button,
  doodleImg,
  backgroundFill,
  gridArea,
}: SetupTeaserProps) {
  return (
    <TeaserWrapper gridArea={gridArea}>
      <TeaserImageWrapper>
        <Spacing mb="spacing-s" media={{ l: { mr: 'spacing-m', mb: 'auto' } }}>
          <TeaserImageInner>
            <TeaserBackground aria-hidden="true" fill={backgroundFill} />
            {doodleImg}
          </TeaserImageInner>
        </Spacing>
      </TeaserImageWrapper>
      <TeaserHeadline>
        <Spacing mb="spacing-l" media={{ l: { mb: 'spacing-xxs' } }}>
          <Headline2>{headline}</Headline2>
        </Spacing>
      </TeaserHeadline>
      <TeaserTextWrapper>
        <Spacing mb="spacing-s" media={{ l: { mb: 'spacing-xxs' } }}>
          <TeaserParagraph size={3}>{text}</TeaserParagraph>
        </Spacing>
      </TeaserTextWrapper>
      <TeaserButtonWrapper>
        <Spacing pt="spacing-xxs" media={{ s: { mt: 'spacing-xxs' } }}>
          {button}
        </Spacing>
      </TeaserButtonWrapper>
    </TeaserWrapper>
  )
}

const welcomeSchema = v.object({
  stage: v.object({
    headline: contentfulDocument,
    backgroundColor: v.string(),
    imageDesktop: contentfulFile,
    imageMobile: contentfulFile,
    text: v.string(),
  }),
  textImageContent: v.object({
    headline: contentfulDocument,
    entries: v.array(
      v.object({
        headline: v.string(),
        description: contentfulDocument,
        imageLarge: contentfulFile,
      })
    ),
  }),
})

export function Welcome() {
  const { login, register } = useAuth()
  const contextTheme = useContext(ThemeContext)
  const imageBackend = useContext(ImageV2Ctx).backend
  const isTabletView = useMedia({ minWidth: contextTheme.screenTabletL })
  const contentfulEnabled = useFlag('contentfulIntegration')
  const { t } = useTranslation('home')

  // we implicitly ignore the error result, since we rendera fallback
  // to ensure the page is still functional (links to login, register and Toniebox setup)
  const contentfulResult = useContentful({
    contentId: '7pHBgsbk6DqDCnicMrvoOd',
    pause: !contentfulEnabled,
    schema: welcomeSchema,
  })

  if (contentfulEnabled && contentfulResult.isFetching) {
    return (
      <StyledLayoutV2 backgroundColor={contextTheme.White}>
        <Placeholder />
      </StyledLayoutV2>
    )
  }

  const data = contentfulResult.data
  const textImageItems: UniqueSellingPointsItem[] | null =
    data &&
    data.textImageContent.entries &&
    data.textImageContent.entries.map(entry => {
      return {
        headlineAsHTMLTag: 'h3',
        headline: entry.headline,
        text: documentToReactComponents(entry.description as Document, {
          renderMark: {
            [MARKS.BOLD]: children => <strong>{children}</strong>,
            [MARKS.ITALIC]: children => <em>{children}</em>,
          },
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => <span>{children}</span>,
          },
        }),
        image: (
          <ImageV2
            src={`${window.location.protocol}${entry.imageLarge.file.url}`}
            alt={entry.headline}
          />
        ),
      }
    })

  const textImageSectionHeadline = data && data.textImageContent.headline
  const stageHeadline = data && data.stage.headline
  const stageBackgroundColor =
    contextTheme.colors[
      data && data.stage.backgroundColor
        ? data.stage.backgroundColor
        : 'lightblue-20'
    ]

  return (
    <StyledLayoutV2 backgroundColor={stageBackgroundColor}>
      <Head pageTitle={t('LoggedOutPageTitle')} />
      <StyledStage
        imageDesktop={
          (data && imageBackend({ src: data.stage.imageDesktop.file.url })) ||
          ''
        }
        imageMobile={
          (data && imageBackend({ src: data.stage.imageMobile.file.url })) || ''
        }
        backgroundColor={stageBackgroundColor}
      >
        <StageContent
          isSmallStage
          headline={
            stageHeadline ? (
              <ContentfulHeadline
                content={stageHeadline}
                headlineTag={stageHeadline ? 'span' : undefined}
              />
            ) : (
              <Trans
                i18nKey="home:HomeLoggeoutFallbackStageHeadline"
                components={{ em: <Accent /> }}
              />
            )
          }
          text={
            data && data.stage.text
              ? data.stage.text
              : t('home:HomeLoggedOutFallbackStageCopy')
          }
        >
          <StageButton
            styling="primary"
            onClick={() => login({ shouldReplaceHistory: false })}
            dataTestId="puppeteer-login"
            data-trackingid="home-logged-out__stage-login__button-press"
          >
            {t('Login')}
          </StageButton>
          <StageButton
            styling="secondaryWhite"
            onClick={() => register({ shouldReplaceHistory: false })}
            dataTestId="puppeteer-register"
            data-trackingid="home-logged-out__stage-register__button-press"
          >
            {t('Register')}
          </StageButton>
        </StageContent>
      </StyledStage>
      <Spacing
        mt="spacing-xl"
        pb="spacing-xxs"
        media={{ m: { pb: 'spacing-xxl' } }}
      >
        <TwoTeaserSectionContainer>
          <TwoTeaserSection>
            <SetupTeaser
              gridArea="Toniebox"
              backgroundFill={contextTheme.colors['lightblue-20']}
              doodleImg={<TeaserImageBox aria-label="Toniebox" role="img" />}
              headline={
                <Trans
                  i18nKey="home:TeaserNewTonieboxHeadline"
                  components={{ em: <Accent /> }}
                />
              }
              text={<Trans i18nKey="home:TeaserNewTonieboxText" />}
              button={
                <OmniButton
                  styling="primary"
                  href={{
                    pathname: '/setup-toniebox',
                    state: {
                      clearState: true,
                    },
                  }}
                  dataTestId="start-full-setup"
                  data-trackingid="home-logged-out__add-toniebox__button-press"
                >
                  <Trans i18nKey="home:TeaserNewTonieboxButton" />
                </OmniButton>
              }
            />
            <SetupTeaser
              gridArea="Wifi"
              doodleImg={<TeaserImageWlan aria-label="Wlan" role="img" />}
              backgroundFill={contextTheme.colors['green-20']}
              headline={
                <Trans
                  i18nKey="home:TeaserNewWifiHeadline"
                  components={{ em: <Accent /> }}
                />
              }
              text={<Trans i18nKey="home:TeaserNewWifiText" />}
              button={
                <OmniButton
                  styling="primary"
                  href="/wifi"
                  dataTestId="start-wifi-setup"
                  data-trackingid="home-logged-out__add-wifi__button-press"
                >
                  <Trans i18nKey="home:TeaserNewWifiButton" />
                </OmniButton>
              }
            />
          </TwoTeaserSection>
        </TwoTeaserSectionContainer>
      </Spacing>

      <Spacing mt="spacing-xxl">
        <SectionInner>
          {!contentfulResult.isFetching && contentfulResult.data && (
            <SectionHeadlineWrapper>
              {textImageSectionHeadline && (
                <ContentfulHeadline
                  headlineTag="h2"
                  content={textImageSectionHeadline}
                />
              )}
            </SectionHeadlineWrapper>
          )}
          {textImageItems && <UniqueSellingPoints items={textImageItems} />}
        </SectionInner>
      </Spacing>

      <TonieWorldSection>
        <TonieWorldCurve
          layout={isTabletView ? 'topLeft' : 'topRight'}
          color={theme.BrandPrimary}
        />
        <TonieWorldWrapper>
          <TonieWorldHeadline color="white" asHTMLTag="h2">
            <Trans
              i18nKey="home:TonieWorldHeadline"
              components={{ em: <Accent /> }}
            />
          </TonieWorldHeadline>
          <TonieWorldParagraph color="white">
            <Trans i18nKey="home:TonieWorldText" />
          </TonieWorldParagraph>
          <TonieWorldLinksWrapper>
            <OmniButton
              styling="secondaryWhite"
              onClick={() => register({ shouldReplaceHistory: false })}
              data-trackingid="home-logged-out__bottom-register__button-press"
            >
              <Trans i18nKey="home:TonieWorldButton" />
            </OmniButton>
            <TonieWorldLogin
              iconEnd={icons.arrowRightFill}
              onClick={() => login({ shouldReplaceHistory: false })}
              data-trackingid="home-logged-out__bottom-login__button-press"
              isWhiteTextLink
            >
              <Trans i18nKey="home:TonieWorldLink" />
            </TonieWorldLogin>
          </TonieWorldLinksWrapper>
          <TonieWorldImageWrapper>
            <ImageV2
              src="https://cdn.tonies.de/mytonies/tonie-world@3x.7f6308d1.png"
              alt="Tonie World"
              ratio={[11, 10]}
              responsive={[308, 440]}
            />
          </TonieWorldImageWrapper>
        </TonieWorldWrapper>
      </TonieWorldSection>
    </StyledLayoutV2>
  )
}
