import {
  Icon,
  media,
  ZendeskWidget as SupportedWidget,
} from '@boxine/tonies-ui'
import { questionMark } from '@boxine/tonies-ui/icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { createGlobalStyle } from 'styled-components'
import useScriptLoader from '../../hooks/useScriptLoader'
import { useUserProfile } from '../../providers/user-profile'
import { Region } from '../../providers/user-profile/profileTypes'
import { zendeskLinks } from '../../zendesk'

const ZendeskStyles = createGlobalStyle`
  /* to hide the actual Zendesk-Widget button; we render our own */
  #launcher {
    display: none!important;
  }
`

const StyledSupportWidget = styled(SupportedWidget)`
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  line-height: 1;
  z-index: 5;
`

const ButtonContainer = styled.span`
  display: flex;
  align-items: center;

  ${media.tabletL`
    padding: 0 0.5rem;
  `}
`

const ZendeskButtonText = styled.span`
  display: none;
  ${media.tabletL`
    display: inline;
  `}
`

type AsideProps = {
  /* This property is used to change the position of the support widget on assign and edit views of the new Creative-
     Tonie detail page. This is needed to prevent assign/save buttons being hidden by the widget.*/
  shouldRespectStickyOverlayOnNewCTPDP?: boolean
}

// TODO: Remove this workaround once the SupportWidget can be disabled or modified via hooks (See shop-frontend)
export const Aside = styled.aside<AsideProps>`
  z-index: ${props =>
    props.shouldRespectStickyOverlayOnNewCTPDP ? '501' : 'initial'};
  > * {
    margin-bottom: ${props =>
      props.shouldRespectStickyOverlayOnNewCTPDP ? '6rem' : 'initial'};
    margin-right: ${props =>
      props.shouldRespectStickyOverlayOnNewCTPDP ? '1rem' : 'initial'};
  }
`

const ZENDESK_KEY = '4c490ca0-9d17-4c0d-9eb7-5e67e1b4bd8e'

const mapping = {
  dach: 'de',
  uki: 'en-gb',
  usa: 'en-us',
  fra: 'fr-fr',
  // this non-standard locale is zendesk's own invention
  eur: 'en-150',
} as const

export function regionToLocale(
  region: Region
): typeof mapping[keyof typeof mapping] {
  return mapping[region] || 'en-gb'
}

export const SupportWidget = () => {
  const { t } = useTranslation('default')
  const { region } = useUserProfile()
  const [initZendesk, setInitZendesk] = useState(false)

  const isFallback = useMemo(() => {
    return Boolean(
      process.env.NODE_ENV === 'test' ||
        ['slow-2g', '2g'].includes(
          navigator?.connection?.effectiveType ?? ''
        ) ||
        navigator?.connection?.saveData
    )
  }, [])

  // Guarantee stable function reference to prevent useScriptLoader
  // from re-rendering TOC-3713
  const updateZendeskDom = useCallback((element: HTMLScriptElement) => {
    window.zESettings = {
      webWidget: {
        zIndex: 9999,
        offset: {
          horizontal: '-14px',
          vertical: '-8px',
        },
      },
    }
    element.setAttribute('id', 'ze-snippet')
  }, [])

  const { error: zendeskError, loaded: zendeskLoaded } = useScriptLoader(
    `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`,
    !isFallback,
    updateZendeskDom,
    !initZendesk
  )

  useEffect(() => {
    if (window.zE) {
      // always set the locale, since Zendesk messes up an Accept-Language of de-DE (german UI, US results)
      window.zE('webWidget', 'setLocale', regionToLocale(region))
      window.zE('webWidget', 'toggle')
    }
    // only run this on load; the effect above handles the language change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zendeskLoaded])

  const zendeskContent = (
    <ButtonContainer>
      <>
        <Icon width={22} type={questionMark} />
        <ZendeskButtonText>{t('HelpButtonText')}</ZendeskButtonText>
      </>
    </ButtonContainer>
  )

  if (isFallback || zendeskError) {
    return (
      <StyledSupportWidget>
        <a href={zendeskLinks().home} target="_blank" rel="noopener noreferrer">
          {zendeskContent}
        </a>
      </StyledSupportWidget>
    )
  }

  const zendeskButton = (
    <button
      onClick={() => {
        if (!initZendesk) {
          setInitZendesk(true)
        } else if (zendeskLoaded) {
          window.zE('webWidget', 'toggle')
        }
      }}
      type="button"
    >
      {zendeskContent}
    </button>
  )

  return (
    <>
      <ZendeskStyles />

      <StyledSupportWidget>{zendeskButton}</StyledSupportWidget>
    </>
  )
}
