import React from 'react'
import { FocusLink, useIsMobileNavigation } from '@boxine/tonies-ui'
import logoMyToniesPath from '@boxine/tonies-ui/assets/svgs/logoMyTonies.svg'
import { useTranslation } from 'react-i18next'

export interface MyToniesLogoProps {
  shouldDisableLink?: boolean
}

export function MyToniesLogo({ shouldDisableLink }: MyToniesLogoProps) {
  const { t } = useTranslation('default')
  const isMobile = useIsMobileNavigation()
  // specify both to avoid layout shift
  const logoWidth = isMobile ? 73 : 100
  const logoHeight = isMobile ? 34 : 47
  const logo = (
    <img
      alt={t('ToHomepage')}
      src={logoMyToniesPath}
      width={logoWidth}
      height={logoHeight}
    />
  )
  return !shouldDisableLink ? <FocusLink to="/">{logo}</FocusLink> : logo
}
