import * as v from '@badrap/valita'
import * as contentful from 'contentful'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserProfile } from '../providers/user-profile'
import { Region } from '../providers/user-profile/profileTypes'

if (
  !process.env.REACT_APP_CONTENTFUL_SPACE ||
  !process.env.REACT_APP_CONTENTFUL_TOKEN
) {
  throw new Error("Can't create contentful client without its env vars")
}

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  host: process.env.REACT_APP_CONTENTFUL_HOST,
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENV,
})

interface RigidPage<PageType> {
  pageDach: PageType
  pageUsa: PageType
  pageFra: PageType
  pageRow: PageType
  pageUki: PageType
  pageEur: PageType
  pageHkg: PageType
  pageCan: PageType
}

function extractRegional<PageType>(
  region: Region,
  response: contentful.Entry<RigidPage<PageType>>
) {
  if (region === 'dach') {
    return response.fields.pageDach
  }
  if (region === 'usa') {
    return response.fields.pageUsa
  }
  if (region === 'fra') {
    return response.fields.pageFra
  }
  if (region === 'uki') {
    return response.fields.pageUki
  }
  if (region === 'eur') {
    return response.fields.pageEur
  }
  if (region === 'hkg') {
    return response.fields.pageHkg
  }
  if (region === 'can') {
    return response.fields.pageCan
  }
  return response.fields.pageRow
}

async function list<PageType>({
  // locale,
  region,
  contentId,
}: {
  locale?: string
  region: Region
  contentId: string
}) {
  const response = await client.getEntry<RigidPage<PageType>>(contentId, {
    // currently unused - bring it back when we add more than our default locale
    // locale: contentfulLocales[locale],
    include: 10,
  })
  return extractRegional(region, response)
}

/**
 * The response from contentful contains a lot of intermediate shapes that
 * hold metadata and other information we don't care about. Therefore this
 * function strips all intermediate data structures.
 *
 * ```js
 * { fields: { a: ... }, metadata: {}, sys: {}} -> { a: ...}
 * ```
 */
function flattenResponse<T>(data: any): T | undefined {
  if (data !== null && typeof data === 'object') {
    const keys = Object.keys(data)
    if (
      keys.length === 3 &&
      'fields' in data &&
      'metadata' in data &&
      'sys' in data
    ) {
      return flattenResponse(data.fields)
    } else if (keys.length === 1 && typeof data.colorName === 'string') {
      return data.colorName.toLowerCase().replace(/ /g, '-')
    } else if (keys.length === 1 && 'sys' in data) {
      // When the content-type is not published we only get an objet
      // with just the "sys" property, but no actual entry.
      return undefined
    } else {
      keys.forEach(key => {
        data[key] = flattenResponse(data[key])
      })
    }
  }

  return data
}

export interface UseContentfulReturn<PageType> {
  isFetching?: boolean
  data: null | PageType
  error: Error | null
}

export function useContentful<PageType = unknown>({
  contentId,
  pause,
  schema,
}: {
  contentId: string
  pause?: boolean
  schema: v.Type<PageType>
}): UseContentfulReturn<PageType> {
  const { i18n, t } = useTranslation()
  const { region } = useUserProfile()

  const [isFetching, setIsFetching] = useState(!pause)
  const [data, setData] = useState<PageType | null>(null)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (pause) return

    let isMounted = true
    setIsFetching(true)

    list<PageType>({ contentId, locale: i18n.language, region })
      .then(response => {
        if (isMounted) {
          setIsFetching(false)

          setData(schema.parse(flattenResponse(response)))

          setError(null)
        }
      })
      .catch(err => {
        if (isMounted) {
          setIsFetching(false)

          // Always log validation errors to make it easy to inspect for editors
          if (err instanceof v.ValitaError) {
            console.error(
              `Failed to retrieve or parse data for contentId ${contentId}`,
              err
            )
          }

          setError(err)
        }
      })

    return () => {
      isMounted = false
    }
  }, [contentId, i18n, region, pause, schema, t])

  // FYI: The API somewhat mirrors urql's `useQuery` hook
  return { isFetching, data, error }
}
