import { useLocalStorage } from '@boxine/tonies-ui'
import * as Sentry from '@sentry/browser'
import Keycloak from 'keycloak-js'
import { useCallback, useEffect, useState } from 'react'
import { isLocalEnvironment } from '../utils/functions/functions'

export type KeycloakStatus =
  | 'UNKNOWN'
  | 'INITIALIZATION_STARTED'
  | 'INITIALIZATION_FAILED'
  | 'AUTHENTICATED'
  | 'NOT_AUTHENTICATED'

/**
 * FIXME: quick fix for the Keycloak 18 Update
 */
type KeycloakInstance = any

let keycloakInstance: KeycloakInstance | undefined

const keycloakOptions = {
  'confidential-port': 0,
  'public-client': true,
  'ssl-required': 'external',
  clientId: 'my-tonies',
  realm: 'tonies',
  resource: 'my-tonies',
  url: `${process.env.REACT_APP_LOGIN_URL}/auth/`,
}

function getKeyCloakInstance(instance?: KeycloakInstance): KeycloakInstance {
  if (instance) return instance

  if (keycloakInstance instanceof Keycloak) return keycloakInstance

  keycloakInstance = Keycloak(keycloakOptions)

  return keycloakInstance
}

/**
 * TODO: TOC-2795 - Refactoring after nonSSO-Code has been removed
 * Inizializing Keycloak
 * Previously the KeycloakProvider of @react-keycloak/web was used, but that doesn't get any infomation about an initalizing error.
 * To intercept the status correctly, we have implemented a statemachine.
 * The statuses 'UNKNOWN', 'INITIALIZATION_FAILED', 'AUTHENTICATED', 'NOT_AUTHENTICATED' are defined here.
 *
 * Facts (and things we may change in the future):
 * - useKeycloak() is only used in src/providers/auth (do we really need this hook?)
 * - argument `instance` is only used for tests
 * - LocalStorage is filled by this hook and the provider (also mentioned in src/providers/auth:96)
 */
const useKeycloak = (instance?: KeycloakInstance) => {
  const keycloak = getKeyCloakInstance(instance)

  const [status, setStatus] = useState<KeycloakStatus>('UNKNOWN')
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>(
    'authorization'
  )

  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>(
    'refreshToken'
  )

  const [idToken, setIdToken] = useLocalStorage<string | undefined>('idToken')
  const [thirdPartyEnabled, setThirdPartyEnabled] = useLocalStorage<
    boolean | null
  >('3rd_party_enabled', null)

  const updateTokens = useCallback(() => {
    setAccessToken(keycloak.token)
    setRefreshToken(keycloak.refreshToken)
    setIdToken(keycloak.idToken)
  }, [keycloak, setAccessToken, setRefreshToken, setIdToken])

  const setNotAuthenticated = () => setStatus('NOT_AUTHENTICATED')

  /**
   * check for 3rd party cookie support
   * FIXME: will hopefully be useless after updating to Keycloak 10
   * FYI: Don't forget to remove the keycloak-js patch when removing
   *   this section
   */
  useEffect(() => {
    if (thirdPartyEnabled === null) {
      const src = `${process.env.REACT_APP_LOGIN_URL}/auth/realms/tonies/protocol/openid-connect/3p-cookies/step1.html`

      const iframe = document.createElement('iframe')
      iframe.setAttribute('src', src)
      iframe.setAttribute('title', 'keycloak-3p-check-iframe')
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      const iframeCallback = (event: WindowEventMap['message']) => {
        let checkLoginIframe = true

        if (iframe.contentWindow !== event.source) return

        if (
          (event.data !== 'supported' && event.data !== 'unsupported') ||
          event.data === 'unsupported'
        ) {
          checkLoginIframe = false
        }

        document.body.removeChild(iframe)
        window.removeEventListener('message', iframeCallback)

        setThirdPartyEnabled(checkLoginIframe)
      }

      window.addEventListener('message', iframeCallback, false)
    }
  }, [setThirdPartyEnabled, thirdPartyEnabled])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (status === 'UNKNOWN') {
      timeout = setTimeout(() => {
        // We assume that if Keycloak doesn't respond within 5000 ms, Keycloak isn't accessible.
        setStatus(status =>
          ['INITIALIZATION_STARTED', 'UNKNOWN'].includes(status)
            ? 'INITIALIZATION_FAILED'
            : status
        )
      }, 5000)

      if (thirdPartyEnabled !== null) {
        setStatus('INITIALIZATION_STARTED')

        keycloak
          .init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-sso.html`,
            token: accessToken,
            refreshToken,
            idToken,
            enableLogging:
              isLocalEnvironment() ||
              process.env.REACT_APP_ENVIRONMENT === 'dev',
            checkLoginIframe: thirdPartyEnabled,
          })
          .then(isAuthenticated => {
            keycloak.onAuthLogout = () => {
              setNotAuthenticated()
            }

            if (isAuthenticated) {
              // necessary for Keycloak-Magic, if localStorage validation is used during initialization
              keycloak
                .updateToken(0)
                .then(() => {
                  updateTokens()
                })
                .then(() => {
                  setStatus('AUTHENTICATED')
                })
                .catch(() => {
                  // User has't a valid session. Token can't be updated. Furthermore the catched error is always 'true'.
                  setNotAuthenticated()
                })
            } else {
              setNotAuthenticated()
            }

            // Clear timeout once keycloak sent a response.
            clearTimeout(timeout)
          })
          .catch(error => {
            // Keycloak throws custom plain objects instead of proper
            // error instances with stack traces ¯\_(ツ)_/¯
            //
            // This leads to The shape of those looks like this:
            // ```
            // { error: string, error_description: string | undefined }
            // ```
            if (error && 'error' in error && 'error_description' in error) {
              error = new Error(`${error.error}: ${error.error_description}`)
            }
            Sentry.captureException(error)

            setStatus('INITIALIZATION_FAILED')
            // Clear timeout once keycloak sent a response.
            clearTimeout(timeout)
          })
      }
    }
  }, [
    accessToken,
    idToken,
    keycloak,
    refreshToken,
    status,
    thirdPartyEnabled,
    updateTokens,
  ])

  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(0)
      .then(() => updateTokens())
      .catch(() => {
        // User has't a valid session. Token can't be updated. Furthermore the catched error is always 'true'.
        setNotAuthenticated()
      })
  }

  return {
    status,
    setNotAuthenticated,
    keycloak: {
      ...keycloak,
      accessToken,
      refreshToken,
      idToken,
    },
  }
}

export default useKeycloak
