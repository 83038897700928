// TODO delete this once we're not using these anymore (everything relevant converted to TS)
import PropTypes from 'prop-types'
import { INVITATION_TYPES, MEMBER_ACCESS_RIGHTS } from '../globals'

export const ChildrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
])

export const InvitationType = PropTypes.shape({
  email: PropTypes.string,
  id: PropTypes.string,
  itype: PropTypes.string,
})

export const HouseholdType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  foreignCreativeTonieContent: PropTypes.bool,
  access: PropTypes.oneOf(Object.keys(MEMBER_ACCESS_RIGHTS)),
  canLeave: PropTypes.bool,
})

export const ChapterType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  transcoding: PropTypes.bool,
  file: PropTypes.string,
  id: PropTypes.string.isRequired,
  seconds: PropTypes.number,
})

export const CreativeTonie = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  live: PropTypes.bool,
  transcoding: PropTypes.bool,
  private: PropTypes.bool,
  imageUrl: PropTypes.string,
  chapters: PropTypes.arrayOf(ChapterType),
  chaptersPresent: PropTypes.number,
  chaptersRemaining: PropTypes.number,
  secondsPresent: PropTypes.number,
  secondsRemaining: PropTypes.number,
  transcodingErrors: PropTypes.array,
})

export const PendingInvitationType = PropTypes.shape({
  token: PropTypes.string,
  itype: PropTypes.oneOf(Object.keys(INVITATION_TYPES)),
  householdName: PropTypes.string,
  householdImage: PropTypes.string,
})

export const userType = PropTypes.shape({
  email: PropTypes.string,
  uuid: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  tracking: PropTypes.bool,
  authCode: PropTypes.string,
  profileImage: PropTypes.string,
  locale: PropTypes.string,
  isEduUser: PropTypes.bool,
  notificationCount: PropTypes.number,
})

export const tonieboxProp = PropTypes.shape({
  id: PropTypes.string,
})
