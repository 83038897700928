import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Price as Strikeprice, getPriceInfos } from '@boxine/tonies-ui'

export type PriceType =
  | { status: 'error' | 'non-purchasable' }
  | {
      status: 'normal'
      currency: string
      centAmount: number
      strikeCentAmount?: number
    }

const Money = styled.span<{ isTunesGridPrice?: boolean }>`
  color: ${props => props.theme.colors.darkergrey};
  font-size: ${props => (props.isTunesGridPrice ? '0.75rem' : '1.25rem')};
  font-weight: ${props => (props.isTunesGridPrice ? '300' : '700')};
`

export const TaxInfo = styled.span`
  color: ${props => props.theme.colors.darkergrey};
  font-family: ${props => props.theme.fonts.serif};
  font-size: 0.875rem;
  font-weight: 300;
  margin-left: 0.5rem;
  white-space: nowrap;
`

export const Label = styled.span`
  color: ${props => props.theme.colors.darkergrey};
  font-family: ${props => props.theme.fonts.serif};
  font-size: 1.5rem;
  font-weight: normal;
  white-space: nowrap;
`

interface PriceProps {
  className?: string
  isTunesGridPrice?: boolean
  isTilesView?: boolean
  align?: 'right' | 'left' | 'center'
  price: PriceType
  withTax?: boolean
  'data-testid'?: string
}

export default function Price({
  price,
  className,
  isTunesGridPrice,
  align,
  withTax = false,
  'data-testid': testId = 'price',
}: PriceProps) {
  const { i18n, t } = useTranslation()

  if (price.status !== 'normal') return null

  const { strikeCentAmount, centAmount, currency } = price

  const formattedPrice = getPriceInfos(
    i18n.language,
    centAmount,
    currency
  ).displayedPrice

  const formattedStrikedPrice = getPriceInfos(
    i18n.language,
    strikeCentAmount,
    currency
  ).displayedPrice

  const isStrikePrice = Boolean(strikeCentAmount)

  return (
    <>
      {isStrikePrice ? (
        <Strikeprice
          isTilesView={isTunesGridPrice}
          align={align}
          strikedPrice={formattedStrikedPrice}
          price={formattedPrice}
        />
      ) : (
        <Money
          data-testid={`${testId}`}
          className={className}
          isTunesGridPrice={isTunesGridPrice}
        >
          {formattedPrice}
        </Money>
      )}
      {withTax && <TaxInfo>{t('default:IncludingTax')}</TaxInfo>}
    </>
  )
}
