export interface Tonie {
  id: string
  title: string
  lock: boolean
  series: {
    id: string
    name: string
    group: {
      id: string
      name: string
    }
  }
  imageUrl: string
  tune?: {
    id: string
    item: {
      title: string
    }
  }
}

export interface Household {
  id: string
  name: string
  access: string
  contentTonies: Tonie[]
}

interface FlattenedTonie {
  household: Household
  tonie: Tonie
}

/**
 * Flatten all tonies across households
 * @param {FlattenedTonie[]} acc
 * @param {Household} household
 * @returns {FlattenedTonie[]}
 */
export function reduceContentTonies(
  acc: FlattenedTonie[],
  household: Household
): FlattenedTonie[] {
  household.contentTonies.forEach(tonie => {
    acc.push({ household, tonie })
  })
  return acc
}

/**
 * Sort tonie by locking state -> alphabetically
 */
export function sortContentTonies(
  a: FlattenedTonie,
  b: FlattenedTonie
): number {
  if (!a.tonie.lock && b.tonie.lock) return -1
  if (a.tonie.lock && !b.tonie.lock) return 1

  // Sort based on series group name / series name / tonie title
  const seriesA = selectContentTonieSeriesName(a.tonie).value
  const seriesB = selectContentTonieSeriesName(b.tonie).value
  const bySeries = seriesA.localeCompare(seriesB)
  if (bySeries !== 0) return bySeries

  // Sort based on tonie title
  return a.tonie.title.localeCompare(b.tonie.title)
}

/**
 * Selects the appropriate title for a given `tonie` object.
 * If the `tonie` has a series and group name, it returns the series group name.
 * If the `tonie` has a series name, it returns the series name.
 * Otherwise, it returns the title of the `tonie`.
 *
 * @param tonie - The `tonie` object for which to select the title.
 * @returns An object with `key` and `value` properties representing the selected title.
 */
export function selectContentTonieSeriesName(tonie: {
  series?: {
    group?: {
      name: string
    }
    name: string
  }
  title: string
}): {
  key: string
  value: string
} {
  if (tonie.series && tonie.series.group) {
    return {
      key: 'series-group-name',
      value: tonie.series.group.name,
    }
  }

  if (tonie.series && tonie.series.name) {
    return {
      key: 'series-name',
      value: tonie.series.name,
    }
  }

  return {
    key: 'title',
    value: tonie.title,
  }
}

/**
 * Selects the description for a given tonie.
 * If the tonie has a tune, it returns the title of the tune's item.
 * If the tonie does not have a tune but has a series, it returns the tonie's title.
 * Otherwise, it returns null (if there is no series or tune).
 * In this case the title is shown elsewhere in the UI (See: src/features/ContentToniePage/ContentTonieCard.tsx)
 * and `selectContentTonieSeriesName` is used
 *
 * @param tonie - The tonie object.
 * @returns The selected description or null.
 */
export function selectContentTonieDescription(tonie: {
  tune?: {
    item: {
      title: string
    }
  }
  series?: {
    name: string
  }
  title: string
}): string | null {
  if (tonie.tune) {
    return tonie.tune.item.title
  }

  if (!tonie.tune && tonie.series) {
    return tonie.title
  }

  return null
}
