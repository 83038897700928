import { useState } from 'react'
import Cookies from 'js-cookie'

/**
 * Inspired by
 * https://github.com/rrudol/useCookie/blob/master/src/index.js
 * useCookie - React Hook for Cookies based on js-cookie
 */
export function useCookie(
  key: string,
  initialValue?: string,
  options?: Cookies.CookieAttributes
): [
  string | undefined,
  (value: string, options?: Cookies.CookieAttributes) => void
] {
  const [innerValue, setInnerValue] = useState(() => Cookies.get(key))

  const setValue = (value: string) => {
    setInnerValue(value)
    Cookies.set(key, value, options)
  }

  if (!innerValue && initialValue) {
    setValue(initialValue)
  }

  return [innerValue, setValue]
}

export default useCookie
