import { toast, useLocalStorage } from '@boxine/tonies-ui'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncEffect } from '../../hooks/useAsyncEffect'
import useKeycloak from '../../hooks/useKeycloak'

export interface UserInterface {
  email: string
  uuid: string
  firstName: string
  lastName: string
  country: string
  tracking: boolean
  authCode: string
  profileImage: string
  unicodeLocale: string
  region: string
  isEduUser: boolean
  notificationCount: number
  canBuyTunes: boolean
  ownsTunes: boolean
  anyPublicContentTokens: boolean
  creativeTonieShopUrl: string
}

export interface AuthContextInterface {
  isAuthenticated: boolean
  isChecked: boolean
  jwt: string | null
  login: (options?: {
    redirectUri?: string
    shouldReplaceHistory?: boolean
  }) => void
  logout: (redirectUri?: URL) => void
  register: (options?: {
    redirectUri?: string
    shouldReplaceHistory?: boolean
  }) => void
  deleteAccount: () => void
  updateEmail: () => void
  updatePassword: () => void
  resetPassword: () => void
  loadUserProfile: () => Promise<any>
}

export const AuthContextDefaultProps = {
  isAuthenticated: false,
  isChecked: false,
  jwt: null,
  login: () => {},
  logout: () => {},
  register: () => {},
  deleteAccount: () => {},
  updateEmail: () => {},
  updatePassword: () => {},
  resetPassword: () => {},
  loadUserProfile: async () => undefined,
}

const Context = React.createContext<AuthContextInterface>(
  AuthContextDefaultProps
)

export function useAuth() {
  const context = useContext(Context)

  if (!context)
    throw new Error(
      'In order to use this hook, please add the <AuthProvider> to your app'
    )

  return context
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const { i18n, t } = useTranslation(['default'])
  const [localStorageToken, setLocalStorageToken] = useLocalStorage<
    string | null
  >('authorization')

  // TODO these useLocalStorage calls are redundant with the ones inside useKeycloak() and are only used to delete data (on logout)
  // to make this flow easier to understand and keep the localStorage access in one place, we should replace these calls with a reset() function exported from useKeycloak()
  const [, setLocalStorageTokenRefresh] = useLocalStorage<string | null>(
    'refreshToken'
  )

  const [, setLocalStorageTokenId] = useLocalStorage<string | null>('idToken')

  const [hasAuthorizationFinished, setHasAuthorizationFinished] =
    useState(false)
  const [, setShouldShowGreetingBubble] = useLocalStorage<boolean | null>(
    'shouldShowGreetingBubble',
    true
  )

  const { keycloak, status } = useKeycloak()

  const resetLocalStorageTokens = () => {
    setLocalStorageToken(null)
    setLocalStorageTokenRefresh(null)
    setLocalStorageTokenId(null)
  }

  useEffect(() => {
    // show TOAST-Message when the status is 'INITIALIZATION_FAILED' (Keycloak-Outage)
    if (status === 'INITIALIZATION_FAILED') {
      toast(
        t('default:TOASTSomethingWentWrongWithErrorCode', {
          errorCode: 403,
        }),
        'error',
        {
          autoClose: false,
          toastId: 'keycloak-outage',
        }
      )
    }
  }, [status, t])

  useAsyncEffect(async () => {
    if (status === 'AUTHENTICATED' && keycloak.accessToken) {
      setHasAuthorizationFinished(true)
    } else if (status === 'NOT_AUTHENTICATED') {
      // cleanup
      resetLocalStorageTokens()
      setShouldShowGreetingBubble(null)
    }
  }, [status, keycloak.accessToken])

  const login = ({
    redirectUri = window.location.href,
    shouldReplaceHistory = true,
  }: {
    redirectUri?: string
    shouldReplaceHistory?: boolean
  } = {}) => {
    // Users may click login before keycloak is initialized in which case the
    // internal adapter is not present and calling `keycloak.login()` will
    // therefore throw. We can detect the adapter being initialized when
    // the status is "NOT_AUTHENTICATED" or "AUTHENTICATED". Check out
    // our "useKeycloak" hook for more details. (TOC-3687)
    if (status === 'NOT_AUTHENTICATED' || status === 'AUTHENTICATED') {
      const loginUrl = keycloak.createLoginUrl({
        redirectUri,
        locale: i18n.language,
      })

      // Inlined variant of `keycloak.login()`. It does nothing more
      // than doing the navigation to the login URL anyway.
      if (shouldReplaceHistory) {
        window.location.replace(loginUrl)
      } else {
        window.location.href = loginUrl
      }
    }
  }

  const register = ({
    redirectUri = window.location.href,
    shouldReplaceHistory = true,
  }: {
    redirectUri?: string
    shouldReplaceHistory?: boolean
  } = {}) => {
    // Keycloak may not finished initializing here. Check out
    // our "useKeycloak" hook for more details. (TOC-3600, related to TOC-3687)
    if (status === 'NOT_AUTHENTICATED' || status === 'AUTHENTICATED') {
      const registerUrl = keycloak.createRegisterUrl({
        redirectUri,
        locale: i18n.language,
      })

      // Inlined variant of `keycloak.register()`. It does nothing more
      // than doing the navigation to the register URL anyway.
      if (shouldReplaceHistory) {
        window.location.replace(registerUrl)
      } else {
        window.location.href = registerUrl
      }
    }
  }

  const resetPassword = () => {
    window.location.href = `${keycloak.authServerUrl}realms/tonies/login-actions/reset-credentials?client_id=${keycloak.clientId}&kc_locale=${i18n.language}`
  }

  const updatePassword = () => {
    const accountUrl = new URL(keycloak.createAccountUrl())
    const passwordUrl = `${accountUrl.origin}${accountUrl.pathname}/password${accountUrl.search}&kc_locale=${i18n.language}`

    window.location.href = passwordUrl
  }

  const deleteAccount = () => {
    const deleteAccountUrl = `${keycloak.authServerUrl}realms/tonies/tonie-account/account/delete?referrer=${keycloak.clientId}&referrer_uri=${window.location.href}&kc_locale=${i18n.language}`

    window.location.href = deleteAccountUrl
  }

  const updateEmail = () => {
    const accountUrl = keycloak.createAccountUrl()
    const updateEmailUrl = `${accountUrl}&kc_locale=${i18n.language}`

    window.location.href = updateEmailUrl
  }

  const logout = (redirectUri?: URL) => {
    const loginUrl = new URL('login', window.location.origin)

    if (redirectUri) {
      loginUrl.searchParams.set(
        'redirect',
        encodeURIComponent(redirectUri.toString())
      )
    }

    keycloak.logout({
      redirectUri: loginUrl.toString(),
      // Backwards compat for Keycloak v8
      // @ts-ignore
      kcLocale: i18n.language,
    })
  }

  const loadUserProfile = async () => {
    if (status !== 'AUTHENTICATED') {
      return
    }

    return await keycloak.loadUserProfile()
  }

  /**
   * To be able to display the pending state of the ProtectedRoutes,
   * we assume that if there is a LocalStorage for authentication,
   * this JWT is valid and return this as True-Value.
   *
   * Therefore, the ProtectedRoutes can still be displayed without
   * routing to the login page. An error message is displayed there.
   */
  const token = useMemo<string | null>((): string | null => {
    if (['AUTHENTICATED', 'UNKNOWN'].includes(status) && localStorageToken) {
      return localStorageToken
    } else {
      return null
    }
  }, [localStorageToken, status])

  return (
    <Context.Provider
      value={{
        isAuthenticated: status === 'AUTHENTICATED' && Boolean(token),
        isChecked:
          status === 'INITIALIZATION_FAILED' ||
          status === 'NOT_AUTHENTICATED' ||
          hasAuthorizationFinished,
        jwt: token,
        login,
        logout,
        register,
        deleteAccount,
        updateEmail,
        updatePassword,
        resetPassword,
        loadUserProfile,
      }}
    >
      {children}
    </Context.Provider>
  )
}
