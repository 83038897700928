import React, { useContext, useMemo, useState, useEffect, useRef } from 'react'
import { useAsyncEffect } from '../../hooks/useAsyncEffect'
import { newNoopAdapter } from './adapter/noop'
import { useShallowMemo } from './utils'
import { FeatureFlagAdapter, FeatureFlagUser, Flags } from './types'

export const FeatureFlagContext = React.createContext<{
  setUser: FeatureFlagAdapter['setUser']
  flags: Flags
}>({
  setUser: () => {},
  flags: {},
})

export interface FeatureFlagProviderProps {
  adapter?: FeatureFlagAdapter
  overrides?: Flags
  defaultValues?: Flags
  children?: any
}

// Adapter _must_ be created outside of render to ensure that we
// hae a stable reference and don't re-initialize it on each render.
// The noopAdapter is mostly used for tests
const noop = newNoopAdapter()

export function FeatureFlagProvider({
  adapter = noop,
  overrides = {},
  defaultValues = {},
  children,
}: FeatureFlagProviderProps) {
  const [flags, setFlags] = useState<Flags>(adapter.allFlags())
  const allFlags = useShallowMemo({
    ...defaultValues,
    ...flags,
    ...overrides,
  })

  const [ready, setReady] = useState(adapter.isReady)

  useEffect(() => {
    const onChange = () => {
      setFlags(adapter.allFlags())
    }
    const onReady = () => {
      setReady(true)
      onChange()
    }

    if (adapter.isReady) {
      onReady()
    } else {
      adapter.on('ready', onReady)
    }

    adapter.on('change', onChange)

    return () => {
      adapter.off('ready', onReady)
      adapter.off('change', onChange)
    }
  }, [adapter])

  // `setUser` is called more often than necessary.
  // Due to the async nature we can end up in a race condition
  // when fetching the user's flags. Therefore we queue
  // up all invocations until the client is ready and process
  // them in sequence.
  const queue = useRef<Array<() => void>>([])
  useAsyncEffect(async () => {
    if (ready) {
      for (const fn of queue.current) {
        await fn()
      }
    }
  }, [ready])

  const ctx = useMemo(
    () => ({
      flags: allFlags,
      setUser: (user: FeatureFlagUser) => {
        if (!ready) {
          queue.current.push(() => adapter.setUser(user))
        } else {
          adapter.setUser(user)
        }
      },
    }),
    [allFlags, adapter, ready]
  )

  return (
    <FeatureFlagContext.Provider value={ctx}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext)
}

/**
 * Simple hook to grab the value of a feature flag
 */
export function useFlag<T = any>(name: string): T {
  return useContext(FeatureFlagContext).flags[name]
}

/**
 * Only return actual flag value, if contentful integration is enabled
 * globally.
 */
export function useContentfulFlag<T = boolean>(name: string): T {
  const { flags } = useContext(FeatureFlagContext)
  return flags.contentfulIntegration && flags[name]
}
