import React from 'react'
import { ImageV2 } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { ellipsify } from '../../utils/functions/functions'
import { Text } from '../Typography'

const TonieWrapper = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.direction === 'row' ? 'row' : 'column')};
`

const TonieImage = styled.div<{ direction: 'row' | 'column' }>`
  position: relative;
  margin-right: ${props => (props.direction === 'row' ? '24px' : '0')};

  &.live {
    :before {
      content: url(${process.env.PUBLIC_URL + '/images/live.svg'});
      left: 0;
      position: absolute;
      width: 45%;
      z-index: 9;
    }
  }
`

export interface TonieProps {
  name: string
  src: string
  alt?: string
  direction?: 'row' | 'column'
  live?: boolean
  maxLength?: number
  width?: number
  ratio?: [number, number]
}

const Tonie = ({
  name,
  src,
  alt = 'Tonie',
  live = false,
  width = 50,
  maxLength = 30,
  direction = 'row',
  ratio = [1, 1],
}: TonieProps) => {
  if (maxLength) name = ellipsify(name, maxLength)

  return (
    <TonieWrapper className="creative-tonie-wrapper" direction={direction}>
      <TonieImage
        className={live ? 'avatar live' : 'avatar'}
        direction={direction}
      >
        <ImageV2
          src={src}
          alt={alt}
          responsive={[width]}
          ratio={ratio}
          // somehow the images are not rendered completely, when lazy loading is active.
          // As all are images >= 50px (nearly similar to the placeholder), loading the placeholder first does not make sense.
          placeholder={false}
        />
      </TonieImage>
      <Text className="content">{name}</Text>
    </TonieWrapper>
  )
}

export default Tonie
