/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'
import { Headline2, Paragraph, media, Spacing } from '@boxine/tonies-ui'
import * as v from '@badrap/valita'
import { BLOCKS, MARKS, Document, INLINES } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import {
  contentfulDocument,
  contentfulFile,
} from '../contentful/checkContentfulData'
import { ContentfulHeadline } from '../contentful/ContentfulRichText'
import { InnerContainer } from '../layouts/LayoutV2'
import { extractTextContent } from '../utils/functions/extractTextContent'
import { Skeleton } from './Skeleton'

export const Wrapper = styled.div`
  background: ${props => props.theme.White};
  padding: 3rem 0 2rem 0;

  ${media.tabletL`
    padding: 3rem 0 3rem 0;
  `}
`

const PartnerLogo = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PartnerImg = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
`

const StyledAnchor = styled.a`
  font-family: ${props => props.theme.fonts.serif};
  color: ${props => props.theme.BrandPrimary};
`

const ColumnLayout = styled.div`
  ${media.tabletL`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}
`

const CenterTextColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const SectionHeadline = styled(Headline2)<{ mobileCenter?: boolean }>`
  line-height: normal;
  margin-bottom: 0.5rem;
  text-align: ${props => (props.mobileCenter ? 'center' : 'left')};

  ${media.tabletL`
    text-align: left;
  `}
`

const SectionText = styled(Paragraph)`
  font-size: 1rem;
  font-weight: lighter;
  line-height: 1.4;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  color: inherit;

  ${media.tabletL`
    font-size: 1.25rem;
    line-height: normal;
  `}
`

export function CooperationSectionPlaceholder() {
  return (
    <Wrapper>
      <InnerContainer>
        <ColumnLayout>
          <CenterTextColumn>
            <div>
              <Spacing mb="spacing-l">
                <Skeleton height={32} width="60%" />
              </Spacing>
              <Spacing mb="spacing-xxs">
                <Skeleton height={20} />
              </Spacing>
              <Spacing mb="spacing-xxs">
                <Skeleton height={20} />
              </Spacing>
              <Spacing mb="spacing-xxs">
                <Skeleton height={20} />
              </Spacing>
              <Spacing mb="spacing-xxs">
                <Skeleton height={20} width="50%" />
              </Spacing>
            </div>
          </CenterTextColumn>
          <Skeleton />
        </ColumnLayout>
      </InnerContainer>
    </Wrapper>
  )
}

export const cooperationSchema = v.object({
  copy: contentfulDocument,
  headline: contentfulDocument,
  imageDesktop: contentfulFile,
  imageMobile: contentfulFile,
  imageLinkTarget: v.string().optional(),
})

export function CooperationSection({
  data,
  landingPageUrl,
}: {
  data: v.Infer<typeof cooperationSchema>
  landingPageUrl: string
}) {
  const logo = (
    <PartnerLogo>
      <source
        srcSet={data.imageMobile.file.url}
        media={`(min-width: ${media.tabletL}px)`}
      />
      <PartnerImg src={data.imageDesktop.file.url} alt="Logo-Ard" />
    </PartnerLogo>
  )

  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: children => <strong>{children}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <SectionText>{children}</SectionText>
      ),
      [INLINES.HYPERLINK]: (node, content) => (
        <StyledAnchor
          target="_blank"
          rel="noopener noreferrer"
          data-trackingid={`landingpage-${landingPageUrl}__cooperation-section__textlink-${extractTextContent(
            content
          )}`}
          href={node.data.uri}
        >
          {content}
        </StyledAnchor>
      ),
    },
  }

  return (
    <Wrapper>
      <InnerContainer>
        <ColumnLayout>
          <CenterTextColumn>
            <SectionHeadline mobileCenter asHTMLTag="h2">
              <ContentfulHeadline headlineTag="span" content={data.headline} />
            </SectionHeadline>
            {documentToReactComponents(data.copy as Document, renderOptions)}
          </CenterTextColumn>
          {data.imageLinkTarget ? (
            <a
              href={data.imageLinkTarget}
              target="_blank"
              rel="noreferrer noopener"
              data-trackingid={`landingpage-${landingPageUrl}__cooperation-section__coop-image-link`}
            >
              {logo}
            </a>
          ) : (
            logo
          )}
        </ColumnLayout>
      </InnerContainer>
    </Wrapper>
  )
}
