import { GeoIpRegions, useEnvLink } from '@boxine/tonies-ui'
import { useUtm } from '../providers/utm/UtmProvider'
import { useUserProfile } from '../providers/user-profile'
import { useFlag } from '../providers/feature-flags'

interface Tune {
  id: string
  title: string
  salesId?: string
  myTune?: Record<string, unknown>
}

/**
 * Custom hook for handling the Tunes Shop PDP (Product Detail Page) redirect functionality.
 * This hook provides functions to create links based on sales IDs and tunes.
 *
 * @returns An object containing the following functions:
 *   - createLinkBySalesId: A function that creates a shop redirect link based on a sales ID.
 *   - createLinkByTune: A function that creates a shop redirect link based on a tune object if the user does not already own it.
 */
export function useTunesShopPDP() {
  const isRedirectAudioLibraryPdpToShopEnabled = useFlag(
    'redirectAudioLibraryPdpToShop'
  )
  const { region } = useUserProfile()
  const { getUtmParams } = useUtm()

  const utmSearchParams = getUtmParams()
  const shopLink = useEnvLink(
    'websiteLink',
    process.env.REACT_APP_ENVIRONMENT === 'local'
      ? 'dev'
      : process.env.REACT_APP_ENVIRONMENT,
    region.toUpperCase() as GeoIpRegions
  )

  /**
   * Appends search parameters to the given URL based on the current window location search parameters.
   * If the 'webview' parameter is present in the search parameters with the value 'app', it adds 'webview=app' to the URL.
   * It also adds any utm parameters to the URL.
   *
   * @param url - The URL to append the search parameters to.
   * @returns The updated URL with the appended search parameters.
   */
  function appendParams(url: string) {
    const searchParams = new URLSearchParams(window.location.search)
    const newUrl = new URL(url)

    // Add webview=app to shopParams if it is present in searchParams
    if (searchParams.get('webview') === 'app') {
      newUrl.searchParams.set('webview', 'app')
    }

    // Add utm params to shopParams if it is present in searchParams
    utmSearchParams.forEach((value, key) => {
      newUrl.searchParams.set(key, value || '')
    })

    return newUrl.toString()
  }

  /**
   * Creates a link by sales ID.
   *
   * @param salesId - The sales ID to create the link for.
   * @returns The created link if the sales ID is provided and the redirect to the audio library PDP to shop feature is enabled, otherwise undefined.
   */
  function createLinkBySalesId(salesId: string | undefined) {
    if (!salesId || !isRedirectAudioLibraryPdpToShopEnabled) {
      return undefined
    }

    return appendParams(`${shopLink}/r/${salesId}`)
  }

  /**
   * Creates a link based on the provided tune.
   * If the tune is undefined, has no salesId, or is marked as myTune, returns undefined.
   * Otherwise, returns a link created using the tune's salesId.
   *
   * @param tune - The tune object to create a link for.
   * @returns The created link or undefined.
   */
  function createLinkByTune(tune: Tune | undefined) {
    if (!tune || !tune.salesId || tune.myTune) {
      return undefined
    }

    return createLinkBySalesId(tune.salesId)
  }

  return {
    createLinkBySalesId,
    createLinkByTune,
  }
}
